import { Controller } from '@hotwired/stimulus';
import { camelCase, forEach } from 'lodash';

export default class extends Controller {
  static values = {
    utmParams: {
      type: Array,
      default: [
        'utm_source',
        'utm_campaign',
        'utm_medium',
        'utm_term',
        'utm_content',
      ],
    },
  };

  initialize() {
    this.utms = {};
    this.setUTMCookies();
  }

  connect() {
    this.setUTM();
  }

  open() {
    Calendly.initPopupWidget({
      url: 'https://calendly.com/d/3qm-b7t-gqb?primary_color=1b62ff',
      ...(Object.keys(this.utms).length > 0 ? { utm: this.utms } : {}),
    });
  }

  findCookie(name) {
    const cookies = Object.fromEntries(
      document.cookie.split('; ').map((cookie) => cookie.split('='))
    );
    return cookies[name] || null;
  }

  setUTMCookies() {
    const params = new URLSearchParams(window.location.search);
    const yearSeconds = 31536000;
    // Save UTM parameters as cookies
    forEach(this.utmParamsValue, (utm) => {
      if (params.has(utm) && !this.findCookie(utm)) {
        document.cookie = `${utm}=${params.get(utm)};max-age=${yearSeconds}`;
      }
    });
  }

  setUTM() {
    // Populate the instance-specific UTM object from cookies
    forEach(this.utmParamsValue, (utm) => {
      const cookieValue = this.findCookie(utm);
      if (cookieValue) {
        this.utms[camelCase(utm)] = cookieValue;
      }
    });
  }
}
