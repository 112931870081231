import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { delay, each, throttle } from 'lodash';

export default class extends Controller {
  static targets = ['theProblem', 'theCost', 'theSolution', 'textWrapper'];

  initialize() {
    this.handleResize = this.handleResize.bind(this);
    this.setTextWrapperHeight = this.setTextWrapperHeight.bind(this);
  }

  connect() {
    if (!this.hasRun) {
      // insure this only runs once.
      gsap.registerPlugin(ScrollTrigger);
      this.initScroll();
      this.setTextWrapperHeight();
      window.addEventListener('resize', throttle(this.handleResize, 100));
      this.hasRun = true;
    }
  }

  desconnect() {
    window.removeEventListener('resize', throttle(this.handleResize, 100));
  }

  setTextWrapperHeight() {
    const {
      theProblemTarget,
      theCostTarget,
      theSolutionTarget,
      textWrapperTarget,
    } = this;
    const scrollText = [theProblemTarget, theCostTarget, theSolutionTarget];
    let maxHeight = 100; //default

    each(scrollText, (el) => {
      const elHeight = el.offsetHeight;
      if (maxHeight < elHeight) {
        maxHeight = elHeight;
      }
    });
    textWrapperTarget.style.height = `${maxHeight}px`;
  }

  handleResize() {
    delay(() => {
      this.setTextWrapperHeight();
    }, 200); // small delay to let elements render.
  }

  initScroll() {
    const triangle = this.element.querySelector('#triangle-svg');
    const triangleTools = this.element.querySelector('#triangle-tools');
    const lostMoney = this.element.querySelector('#lost-money');
    const lostPeople = this.element.querySelector('#lost-people');
    const lostData = this.element.querySelector('#lost-data');
    const lostTime = this.element.querySelector('#lost-time');
    const badgesSvg = this.element.querySelector('#triangle-badges');
    const builtCircle = this.element.querySelector('#triangle-built-circle');
    const tl1 = gsap.timeline();
    const tl2 = gsap.timeline();
    const tl3 = gsap.timeline();

    const mainTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.element,
        pin: true,
        start: 'top top',
        end: () => `+=${this.element.offsetWidth * 3}`,
        scrub: 0.1,
        snap: {
          snapTo: [0, 0.5, 1],
          ease: 'power1.out',
        },
      },
    });

    const triangleTL = gsap.timeline({
      scrollTrigger: {
        trigger: this.element,
        start: '-10% 40%',
        end: 'top',
        onUpdate: (self) => {
          if (!self.progress) {
            triangle.classList.remove('triangle-scroll');
          }
        },
      },
    });

    triangleTL
      .from(triangle, {
        onStart: () => {
          triangle.setAttribute('class', 'triangle-scroll');
        },
        opacity: 0,
      })
      .to(triangle, {
        opacity: 1,
      });

    tl1
      .addLabel('tl1Start')
      .from(triangleTools, {
        opacity: 0,
        rotate: 180,
        scale: 0.3,
      })
      .to(triangleTools, {
        rotate: 0,
        opacity: 1,
        scale: 1,
      })
      .addLabel('tl1End');

    triangleTL.add(tl1);

    tl2
      .addLabel('tl2Start', 0)
      .to(this.theProblemTarget, {
        opacity: 0,
        y: -100,
      })
      .from(this.theCostTarget, {
        opacity: 0,
        y: 100,
      })
      .to(this.theCostTarget, {
        opacity: 1,
        y: 0,
      })
      .to(triangleTools, {
        opacity: 0,
        rotate: 180,
      })
      .to(lostMoney, {
        opacity: 1,
      })
      .to(lostPeople, {
        opacity: 1,
      })
      .to(lostData, {
        opacity: 1,
      })
      .to(lostTime, {
        opacity: 1,
      })
      .addLabel('tl2End', '50%');

    tl3
      .addLabel('tl3Start', '50%')
      .to(this.theCostTarget, {
        opacity: 0,
        y: -100,
      })
      .to(badgesSvg, {
        opacity: 0,
      })
      .from(this.theSolutionTarget, {
        opacity: 0,
        y: 100,
      })
      .to(this.theSolutionTarget, {
        opacity: 1,
        y: 0,
      })
      .from(builtCircle, {
        opacity: 0,
        scale: 1.6,
      })
      .to(builtCircle, {
        opacity: 1,
        scale: 1,
      })
      .addLabel('tl3End', '100%');

    tl2.timeScale(1).duration(1.2);
    tl3.timeScale(1).duration(1.2);

    mainTimeline.add(tl2, 0).add(tl3, '50%');
  }
}
