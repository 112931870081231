import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['mobileNav', 'logo', 'mainNav', 'sideNav', 'backBtn'];
  static classes = ['active'];

  initialize() {
    this.activeSideNav = '';
  }

  open() {
    this.preventBodyScroll(true);
    this.mobileNavTarget.classList.remove('hidden');

    setTimeout(() => {
      this.mobileNavTarget.classList.add(this.activeClass);
    }, 100);
  }

  close() {
    this.preventBodyScroll(false);
    this.mobileNavTarget.classList.remove(this.activeClass);

    setTimeout(() => {
      this.mobileNavTarget.classList.add('hidden');
    }, 400);
  }

  handleActiveSubnav({ params: { id } }) {
    this.sideNavTargets.forEach((sideNav) => {
      if (sideNav.id == id) {
        this.mainNavTarget.classList.add('hide');
        this.backBtnTarget.classList.remove('hidden');
        this.logoTarget.classList.add('hidden');

        setTimeout(() => {
          this.mainNavTarget.classList.add('hidden');
          sideNav.classList.remove('hidden');
          sideNav.classList.add('active');
        }, 200);

        setTimeout(() => {
          sideNav.classList.add('active');
        }, 400);

        this.activeSideNav = id;
        return;
      }
    });
  }

  removeActiveSubnav() {
    this.sideNavTargets.forEach((sideNav) => {
      if (sideNav.id == this.activeSideNav) {
        this.backBtnTarget.classList.add('hidden');
        this.logoTarget.classList.remove('hidden');

        this.mainNavTarget.classList.remove('hidden');
        this.mainNavTarget.classList.remove('hide');
        sideNav.classList.remove('active');
        sideNav.classList.add('hidden');
      }
    });

    this.activeSideNav = '';
  }

  preventBodyScroll(isPrevented) {
    const body = document.querySelector('body');
    if (isPrevented) {
      body.classList.add('overflow-hidden');
      return;
    }
    body.classList.remove('overflow-hidden');
  }
}
