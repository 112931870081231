// To see this message, follow the instructions for your Ruby framework.
//
// When using a plain API, perhaps it's better to generate an HTML entrypoint
// and link to the scripts and stylesheets, and let Vite transform it.
import * as Turbo from '@hotwired/turbo';
console.log('Vite ⚡️ Ruby');
import '/controllers/index.js';

// Example: Import a stylesheet in <sourceCodeDir>/index.css
// import '~/index.css'
// import '@/entrypoints/site.scss';
