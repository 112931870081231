import { Controller } from '@hotwired/stimulus';
import Colcade from 'colcade';

export default class extends Controller {
  connect() {
    new Colcade(this.element, {
      columns: '.msnry-grid-col',
      items: '.msnry-grid-item',
    });
  }
}
