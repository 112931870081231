function animationThrottle(fn) {
  let requested = false;
  return function (...args) {
    if (!requested) {
      requested = true;
      requestAnimationFrame(() => {
        fn(...args);
        requested = false;
      });
    }
  };
}

export { animationThrottle };
