import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['btn', 'content'];
  static classes = ['btnActive', 'contentActive'];

  toggle() {
    this.btnTarget.classList.toggle(this.btnActiveClass);
    this.contentTarget.classList.toggle(this.contentActiveClass);
  }
}
