import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const app = Application.start();

const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(app, controllers);

// enable Stimulus debug mode in development
app.debug = process.env.NODE_ENV == 'development';
app.handleError = (error, message, detail) => {
  console.warn(message, detail);
  ErrorTrackingSystem.captureException(error);
};
