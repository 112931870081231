import { Controller } from '@hotwired/stimulus';
import { animationThrottle } from '../javascripts/utils';

export default class extends Controller {
  static targets = ['dropdown'];
  static classes = ['scrolled', 'scrolledActive', 'ddActive'];
  static values = {
    prevScrollPos: { type: Number },
    threshold: { type: Number, default: 4 },
  };

  initialize() {
    this.hasScrolled = this.hasScrolled.bind(this);
  }

  connect() {
    this.prevScrollPosValue = window.scrollY;
    this.initScroll();
    this.handleDropdowns();
    this.hasScrolled();
  }

  disconnect() {
    window.removeEventListener('scroll', animationThrottle(this.hasScrolled));
  }

  initScroll() {
    window.addEventListener('scroll', animationThrottle(this.hasScrolled));
  }

  hasScrolled() {
    const { element, scrolledClass, scrolledActiveClass, thresholdValue } =
      this;
    const navHeight = element.offsetHeight;
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > navHeight + thresholdValue) {
      element.classList.add(scrolledClass);
    } else {
      element.classList.remove(scrolledClass);
    }

    if (currentScrollPos < this.prevScrollPosValue) {
      element.classList.add(scrolledActiveClass);
    } else {
      element.classList.remove(scrolledActiveClass);
    }

    this.prevScrollPosValue = currentScrollPos;
  }

  handleDropdowns() {
    this.dropdownTargets.forEach((element) => {
      const btn = element.querySelector('.btn-dropdown');

      btn.addEventListener('click', () => {
        element.classList.toggle('active');
      });
      element.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
          e.target.blur();
        }
      });
      element.addEventListener('focusout', () => {
        setTimeout(() => {
          element.classList.remove('active');
        }, 100);
      });
      element.addEventListener('mouseover', () => {
        element.classList.add('active');
      });
      element.addEventListener('mouseout', () => {
        element.classList.remove('active');
      });
    });
  }
}
